<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <PageHeader
      :title="entity?.title || title"
      :lead="newsDate?.formatted"
      blokkli
    />

    <div class="layout-grid">
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="bg-white relative z-20 col-start-1 col-end-[-1]"
        drop-alignment="vertical"
      />
    </div>
    <BlokkliField
      :list="paragraphContactTeaser"
      name="field_paragraph_contact_teaser"
      class="mt-32"
    />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeNewsFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  title?: string
  id?: string
  blokkliProps: NodeNewsFragment['blokkliProps']
  paragraphs?: NodeNewsFragment['paragraphs']
  paragraphContactTeaser?: NodeNewsFragment['paragraphContactTeaser']
  body?: string
  canEdit?: NodeNewsFragment['canEdit']
  newsDate?: NodeNewsFragment['newsDate']
}>()
useCurrentNode(props)
</script>
