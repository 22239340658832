<template>
  <main>
    <PageHeader :title="title" :lead="lead" />

    <div class="layout-grid bg-white">
      <NodeFinancialDataSection
        id="forex"
        :title="titleDevisen"
        :lead="subtitleDevisen"
        :table="tableDevisen"
      />

      <NodeFinancialDataSection
        id="banknote-rate"
        :title="titleNotenkurse"
        :lead="subtitleNotenkurse"
        :table="tableNotenkurse"
      />

      <NodeFinancialDataSection
        id="money-market"
        :title="titleGeldmarkt"
        :lead="titleCall"
        :text="subtitleCall"
      />

      <NodeFinancialDataSection
        id="term-deposit"
        :title="titleTermingeld"
        :lead="subtitleTermingeld"
        :table="tableTermingeld"
      />

      <NodeFinancialDataSection
        id="medium-term-notes"
        :title="titleKassenobligationen"
        :lead="subtitleKassenobligationen"
        :table="tableKassenobligationen"
      />

      <NodeFinancialDataSection
        id="precious-metal-rates"
        :title="titleEdelmetall"
        :lead="subtitleEdelmetall"
        :table="tableEdelmetall"
      />

      <NodeFinancialDataSection id="body" :text="body">
        <button class="button mt-48 print:hidden" @click.prevent="onClickPrint">
          <SpriteSymbol name="printer" />
          Seite drucken
        </button>
      </NodeFinancialDataSection>
    </div>
  </main>
</template>

<script lang="ts" setup>
import type { NodeFinancialDataFragment } from '#graphql-operations'
import { INJECT_ENTITY_CONTEXT } from '#blokkli/helpers/symbols'

const props = defineProps<NodeFinancialDataFragment>()
useCurrentNode(props)

provide(INJECT_ENTITY_CONTEXT, {
  uuid: 'not-needed',
  type: 'node',
  bundle: 'financial_data',
})

function onClickPrint() {
  window.print()
}

defineOptions({
  name: 'NodeFinancialData',
})
</script>
