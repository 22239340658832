<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <PageHeader :title="entity?.title || title" :lead="lead" />

    <div class="layout-grid">
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="bg-white relative z-20 col-start-1 col-end-[-1]"
      />
    </div>
    <BlokkliField
      :list="paragraphContactTeaser"
      name="field_paragraph_contact_teaser"
      class="mt-32"
    />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeEventFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  id?: string
  title?: string
  blokkliProps: NodeEventFragment['blokkliProps']
  canEdit?: NodeEventFragment['canEdit']
  paragraphs?: NodeEventFragment['paragraphs']
  paragraphContactTeaser?: NodeEventFragment['paragraphContactTeaser']
  dateStart?: NodeEventFragment['dateStart']
  dateEnd?: NodeEventFragment['dateEnd']
  address?: NodeEventFragment['address']
}>()

useCurrentNode(props)

function getEventDuration(
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
) {
  if (!startDate) {
    return ''
  }

  // Starts and ends on the same day.
  if (startDate === endDate) {
    // Start and end time set to midnight: Event takes place the whole day.
    if (startTime === '0.00' && endTime === '0.00') {
      return startDate
      // Start and end time are the same: Event is open end.
    } else if (startTime === endTime) {
      return `${startDate}, ab ${startTime} Uhr`
    }

    // Event starts and ends on same day, but has a distinct start and end
    // time.
    return `${startDate}, ${startTime} - ${endTime} Uhr`
  }

  // Start and end date different and both times set to midnight.
  // Event takes place across multiple days but with no fixed time schedule.
  if (startTime === '0.00' && endTime === '0.00') {
    return `${startDate} - ${endDate}`
  }

  // Event starts and ends on different days with with fixed start and end
  // times.
  return `${startDate}, ${startTime} Uhr - ${endDate}, ${endTime} Uhr`
}

const date = computed(() => {
  if (
    props.dateStart?.date &&
    props.dateStart.time &&
    props.dateEnd?.date &&
    props.dateEnd.time
  ) {
    return getEventDuration(
      props.dateStart.date,
      props.dateStart.time,
      props.dateEnd.date,
      props.dateEnd.time,
    )
  }
  return undefined
})

const addressText = computed(() => {
  const organization = props.address?.organization || ''
  const street = props.address?.addressLine1 || ''
  const additional = props.address?.addressLine2 || ''
  const locality = [props.address?.postalCode, props.address?.locality]
    .filter(Boolean)
    .join(' ')

  return [organization, street, additional, locality].filter(Boolean).join(', ')
})

const lead = computed(() => {
  return [date.value, addressText.value].filter(Boolean).join('<br>')
})
</script>
