<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <PageHeader
      :title="entity?.title || title"
      :lead="entity?.lead || lead"
      blokkli
    />

    <div class="layout-grid">
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="bg-white relative z-20 col-start-1 col-end-[-1]"
        drop-alignment="vertical"
      />
    </div>

    <div class="bg-white layout-grid section-padding">
      <div class="col-start-2 col-end-[-2] md:col-end-8">
        <h2 class="typo-h2 mb-20">
          {{ $texts('agency_location', 'Standort') }}
        </h2>
        <address v-if="address" class="not-italic mb-28">
          <a
            v-if="googleMapsLink"
            class="button float-right"
            :href="googleMapsLink"
            target="_blank"
            rel="noopener"
          >
            {{ $texts('show_on_google_maps', 'In Google Maps anzeigen') }}
          </a>
          <div>{{ address.addressLine1 }}</div>
          <div>{{ address.postalCode }} {{ address.locality }}</div>
          <div v-if="phone">
            <FieldItemTypeTelephone
              v-slot="{ label }"
              v-bind="phone"
              class="link"
            >
              <SpriteSymbol name="phone" />
              {{ label }}
            </FieldItemTypeTelephone>
          </div>
          <div v-if="fax">
            <FieldItemTypeTelephone
              v-slot="{ label }"
              v-bind="fax"
              class="link"
            >
              <SpriteSymbol name="printer" />
              {{ label }}
            </FieldItemTypeTelephone>
          </div>
        </address>
        <template v-if="journeyPublicTransport">
          <h3 class="typo-h3 mb-8">
            {{
              $texts(
                'agency_arrival_public_transport',
                'Anreise mit öffentlichen Verkehrsmitteln',
              )
            }}
          </h3>
          <p v-html="$sup(journeyPublicTransport)" />
        </template>
        <template v-if="journeyCar">
          <h3 class="typo-h3 mb-8 mt-20">
            {{ $texts('agency_arrival_car', 'Anreise mit dem Auto') }}
          </h3>
          <p v-html="$sup(journeyCar)" />
        </template>
        <template v-if="freeParking">
          <h3 class="typo-h3 mb-8 mt-20">
            {{ $texts('agency_free_parking', 'Gebührenfreie Parkplätze') }}
          </h3>
          <p v-html="$sup(freeParking)" />
        </template>
      </div>
      <div class="col-start-2 md:col-start-8 col-end-[-2]">
        <h2 class="typo-h2 mb-20">
          {{ $texts('agency_openinghours', 'Öffnungszeiten') }}
        </h2>
        <div v-if="hasCounter">
          <h3 class="typo-h3 mb-8">
            {{ $texts('agency_counter', 'Schalter') }}
          </h3>
          <OpeningHours v-if="openingHoursInside" :days="openingHoursInside" />
          <p class="mt-8">
            {{
              $texts(
                'agency_openinghours_inside',
                'Gerne beraten wir Sie auch ausserhalb der Schalteröffnungszeiten von 8:00 Uhr bis 20:00 Uhr. Bitte vereinbaren Sie einen Termin.',
              )
            }}
          </p>
        </div>
        <p v-else>
          {{
            $texts(
              'agency_openinghours_outside',
              'Wir stehen Ihnen auf Voranmeldung zur Verfügung – auch ausserhalb der üblichen Bürozeiten.',
            )
          }}
        </p>
        <template v-if="openingHoursSelfService && hasSelfService">
          <h3 class="typo-h3 mb-8 mt-36">
            {{ $texts('agency_self_service', 'Selbstbedienungszone') }}
          </h3>
          <OpeningHours :days="openingHoursSelfService" />
        </template>
      </div>
    </div>
    <section v-if="image" class="layout-grid">
      <MediaImage
        :image="image.image"
        :image-style="imageStyle"
        class="col-start-1 col-span-full"
      />
    </section>

    <BlokkliField
      :list="paragraphContactTeaser"
      name="field_paragraph_contact_teaser"
      class="mt-32"
    />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeAgencyFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  id?: string
  title?: string
  lead?: string
  blokkliProps: NodeAgencyFragment['blokkliProps']
  canEdit?: NodeAgencyFragment['canEdit']
  paragraphs?: NodeAgencyFragment['paragraphs']
  paragraphContactTeaser?: NodeAgencyFragment['paragraphContactTeaser']
  image?: NodeAgencyFragment['image']
  address?: NodeAgencyFragment['address']
  chatAvailable?: boolean
  phone?: NodeAgencyFragment['phone']
  fax?: NodeAgencyFragment['fax']
  freeParking?: string
  journeyCar?: string
  journeyPublicTransport?: string
  hasSelfService?: boolean
  hasCounter?: boolean
  factsheet?: NodeAgencyFragment['factsheet']
  geoLocation?: NodeAgencyFragment['geoLocation']
  openingHoursInside?: NodeAgencyFragment['openingHoursInside']
  openingHoursSelfService?: NodeAgencyFragment['openingHoursSelfService']
  mapsId?: string
  mapsUrl?: NodeAgencyFragment['mapsUrl']
}>()

useCurrentNode(props)

const { $sup } = useNuxtApp()
const { $texts } = useEasyTexts()

const googleMapsLink = computed(() => {
  if (props.mapsUrl?.uri?.path) {
    return props.mapsUrl.uri.path
  }

  if (!props.mapsId) {
    return
  }
  return 'https://goo.gl/maps/' + props.mapsId
})

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 2 / 1,
  sizes: {
    sm: 450,
    lg: 1024,
    xl: 1500,
  },
})
</script>
