<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <PageHeader
      :title="entity?.title || title"
      :lead="entity?.lead || lead"
      blokkli
    />

    <div class="layout-grid bg-white">
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="bg-white relative z-20 col-start-1 col-end-[-1]"
        drop-alignment="vertical"
      />
      <div class="col-start-2 col-end-[-2] section-padding">
        <Webform
          v-if="webformUrl"
          :webform-url="webformUrl"
          :source-entity-id="nodeId"
        />
      </div>
    </div>
    <BlokkliField
      :list="paragraphContactTeaser"
      name="field_paragraph_contact_teaser"
      class="mt-32"
    />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeWebformFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  id?: string
  title?: string
  lead?: string
  blokkliProps: NodeWebformFragment['blokkliProps']
  canEdit?: NodeWebformFragment['canEdit']
  paragraphs?: NodeWebformFragment['paragraphs']
  paragraphContactTeaser?: NodeWebformFragment['paragraphContactTeaser']
  nodeId?: string
  webform?: NodeWebformFragment['webform']
}>()

useCurrentNode(props)

const webformUrl = computed(() => props.webform?.formUrl?.path)
</script>
