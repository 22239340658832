<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <div
      class="layout-grid py-80 xl:py-112 items-center print:py-10 until-md:!pb-0"
    >
      <div class="col-start-2 col-end-[-2] lg:col-end-9 xl:col-end-10">
        <BlokkliEditable
          v-if="entity?.title || title"
          v-slot="{ value }"
          :value="entity?.title || title || ''"
          tag="h1"
          name="title"
          class="font-bold typo-h1 text-balance hyphens-auto"
        >
          <div v-html="$sup(value)" />
        </BlokkliEditable>
        <BlokkliEditable
          v-if="entity?.lead || lead"
          v-slot="{ value }"
          :value="entity?.lead || lead || ''"
          tag="div"
          name="field_subtitle"
          class="text-gray-400 font-bold mt-10 typo-lead-1 text-balance"
        >
          <p v-html="$sup(value)" />
        </BlokkliEditable>
      </div>
      <div
        class="bg-white md:shadow-xl -mx-16 md:mx-0 col-start-2 col-end-[-2] lg:col-start-9 xl:col-start-10 mt-24 lg:mt-0"
      >
        <div class="p-16 md:p-20">
          <TaxonomyTermIcons
            v-if="icon"
            v-bind="icon"
            class="w-32 aspect-square md:w-48 float-right ml-10 mb-3"
          />
          <p
            class="font-bold text-xl hidden md:block"
            :class="price ? 'mb-1' : 'mb-10'"
            v-html="$sup(title)"
          />
          <p v-if="price" class="text-gray-700 font-bold mb-15">{{ price }}</p>
          <FeatureList v-if="features?.length" :features="features" />
        </div>
        <div v-if="cta" class="p-16 md:p-0 print:hidden">
          <VuepalLink
            class="button is-primary w-full px-20 text-base"
            :to="cta.url.path"
          >
            <div v-html="$sup(cta.label)" />
            <SpriteSymbol name="cta-circle" class="ml-auto" />
          </VuepalLink>
        </div>
      </div>
    </div>

    <div class="layout-grid">
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="col-start-1 col-end-[-1] bg-white"
        drop-alignment="vertical"
      />
    </div>
    <BlokkliField
      :list="paragraphContactTeaser"
      name="field_paragraph_contact_teaser"
      class="mt-32"
    />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeServiceFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  id?: string
  title?: string
  lead?: string
  blokkliProps: NodeServiceFragment['blokkliProps']
  paragraphs?: NodeServiceFragment['paragraphs']
  paragraphContactTeaser?: NodeServiceFragment['paragraphContactTeaser']
  body?: string
  canEdit?: NodeServiceFragment['canEdit']
  icon?: NodeServiceFragment['icon']
  price?: NodeServiceFragment['price']
  features?: NodeServiceFragment['features']
  cta?: NodeServiceFragment['cta']
}>()

useCurrentNode(props)

const { $sup } = useNuxtApp()
</script>
