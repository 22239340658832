<template>
  <BlokkliProvider
    v-if="blokkliProps"
    v-slot="{ entity }"
    v-bind="blokkliProps"
    :entity="props"
  >
    <PageHeader :title="entity?.title || title" :lead="lead" blokkli>
      <MediaImage
        v-if="image"
        v-bind="image"
        :image-style="imageStyle"
        class="order-last md:ml-auto mb-24 md:mb-0 max-w-screen-sm"
      />
    </PageHeader>

    <div class="layout-grid">
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="bg-white relative z-20 col-start-1 col-end-[-1]"
        drop-alignment="vertical"
      />
    </div>
    <BlokkliField
      :list="paragraphContactTeaser"
      name="field_paragraph_contact_teaser"
      class="mt-32"
    />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodePersonFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  id?: string
  title?: string
  anchorId?: string
  blokkliProps: NodePersonFragment['blokkliProps']
  paragraphs?: NodePersonFragment['paragraphs']
  paragraphContactTeaser?: NodePersonFragment['paragraphContactTeaser']
  body?: string
  canEdit?: NodePersonFragment['canEdit']
  lastName?: NodePersonFragment['lastName']
  firstName?: NodePersonFragment['firstName']
  email?: NodePersonFragment['email']
  phone?: NodePersonFragment['phone']
  details?: NodePersonFragment['details']
  image?: NodePersonFragment['image']
  isManagement?: boolean
}>()

useCurrentNode(props)

const lead = computed(() => (props.details || []).join('<br>'))

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    sm: 420,
    md: 300,
    lg: 500,
  },
})

if (!props.isManagement) {
  const data = await useInitData()
  const pagePeople = data.value.globalConfig.pagePeople
  if (!pagePeople) {
    throw createError({
      statusCode: 404,
      fatal: true,
    })
  }

  navigateTo(
    {
      path: pagePeople,
      hash: '#' + props.anchorId,
    },
    {
      redirectCode: 301,
    },
  )
}
</script>
