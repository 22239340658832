<template>
  <NodeService v-if="node && 'price' in node" v-bind="node" />
  <NodeEvent v-else-if="node && 'dateStart' in node" v-bind="node" />
  <NodeWebform v-else-if="node && 'webform' in node" v-bind="node" />
  <NodeAgency v-else-if="node && 'journeyCar' in node" v-bind="node" />
  <NodeNews v-else-if="node && 'newsDate' in node" v-bind="node" />
  <NodePerson v-else-if="node && 'firstName' in node" v-bind="node" />
  <NodeFinancialData v-else-if="node && 'tableDevisen' in node" v-bind="node" />
  <NodePublication
    v-else-if="node && 'publicationDate' in node"
    v-bind="node"
  />
  <NodePage v-else-if="node" v-bind="node" />
</template>

<script lang="ts" setup>
import type {
  NodePageFragment,
  NodeServiceFragment,
  NodeWebformFragment,
  NodeEventFragment,
  NodeAgencyFragment,
  NodePublicationFragment,
  NodeNewsFragment,
  NodePersonFragment,
  NodeFinancialDataFragment,
} from '#graphql-operations'

defineOptions({
  name: 'PageSlug',
})

definePageMeta({
  name: 'drupal-route',
  path: '/:slug(.*)*',
  drupalRoute: true,
})

const nuxtRoute = useRoute()

// Get the data.
const { data: query } = await useAsyncData(nuxtRoute.path, async () => {
  return await useCachedGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => {
    return v.data
  })
})

// Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<
  | NodePageFragment
  | NodeServiceFragment
  | NodeWebformFragment
  | NodeEventFragment
  | NodeAgencyFragment
  | NodePublicationFragment
  | NodeNewsFragment
  | NodePersonFragment
  | NodeFinancialDataFragment
>(query.value)

setBreadcrumbLinksFromRoute(query.value)

await renderPageDependencies()
</script>
