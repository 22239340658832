<template>
  <section
    class="col-start-1 col-end-[-1] section-padding not-last:border-b not-last:border-b-gray-300 layout-grid print:border-b-0"
  >
    <div class="col-start-2 col-end-[-2] print:break-inside-avoid">
      <header v-if="title || lead" :id="id" class="md:mb-28">
        <h2 v-if="title" class="typo-h2">{{ title }}</h2>
        <p
          v-if="lead"
          class="typo-lead-2 text-gray-500 print:!mt-0 print:!text-base"
        >
          {{ lead }}
        </p>
      </header>
      <div
        v-if="tableMarkup"
        class="financial-data-table print:text-xs lg:text-lg"
        :class="'is-' + id"
        v-html="tableMarkup"
      />
      <div
        v-if="text"
        class="ck-content max-w-5xl print:!text-sm print:mt-10 lg:text-lg"
        v-html="$sup(text)"
      />
      <slot />
    </div>
  </section>
</template>

<script lang="ts" setup>
const props = defineProps<{
  id: string
  title?: string
  lead?: string
  table?: { markup?: string }
  text?: string
}>()

const { $sup } = useNuxtApp()

const tableMarkup = computed(() => {
  if (!props.table?.markup) {
    return
  }

  return props.table.markup.replace(/.(CHF.+)/g, '<br>$1')
})

defineOptions({
  name: 'NodeFinancialDataSection',
})
</script>

<style lang="postcss">
.financial-data-table {
  @apply overflow-auto;
  border-collapse: collapse;
  table {
    @apply w-full;
  }
  td,
  th {
    @apply text-left border-b border-b-gray-300 py-8 whitespace-nowrap align-bottom bg-white;
    &:not(:last-child) {
      @apply pr-8;
    }
    &:first-child {
      @apply sticky left-0;
    }
  }
  table tr td:nth-child(n + 3),
  table tr th:nth-child(n + 3) {
    text-align: right;
  }

  &.is-medium-term-notes {
    td {
      @apply first:w-[300px];
    }
  }
}
</style>
